<template>


  <!DOCTYPE html>
  <html>

  <head>
    <meta name="viewport" content="width=device-width" />
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <!-- Google Fonts -->
    <link href='https://fonts.googleapis.com/earlyaccess/droidarabickufi.css' rel='stylesheet'>
  </head>

  <body style="margin: 0;" class=" ">
  <table   class="body">
    <tr>
      <!---->
      <td class="container">

        <div class="header">
          <table   width="100%">
            <tr>
              <td class="align-center" width="100%">
                <a  target="_blank" href="https://lebanesekataeb.com/"><img src="https://www.lebanesekataeb.com/assets/images/en/logo.png" width="200" alt="Postdrop"></a>
              </td>
            </tr>
          </table>
        </div>
        <div class="main-banner">
          <table   width="100%">
            <tr>
              <td class="align-center landing" width="100%">
                <div  class="landing__banner  " style="background-image: url(https://electionback.dlclients.xyz/storage/panels/images/WAsaPmR7XBpazqv9pRZ60Pb0js3XFQsGSa5269qa.jpg);"></div>
              </td>
            </tr>
          </table>
        </div>

        <div class="content">
          <!-- START CENTERED WHITE CONTAINER -->
          <div class="camp">
            <table   width="100%">
              <tr>
                <td class="align-center  landing__campaign " width="100%">
                  <div  class="campaign__main">
                    <a href="#" target="_blank" class="a">
                      <div  class="video">
                        <div   class="video__frame">
                          <img class="v-img" src="https://electionback.dlclients.xyz/storage/panels/images/WAsaPmR7XBpazqv9pRZ60Pb0js3XFQsGSa5269qa.jpg"  height="230" alt="Postdrop">

                        </div>
                        <div   class="video__content"><h2   class="video__content__title md-t black">الحملة الانتخابية لحزب الكتائب اللبنانية</h2>
                          <button class="video__content__button" tabindex="0">الحملة</button>
                        </div>
                      </div>
                    </a>


                  </div>





                </td>
              </tr>
            </table>
          </div>

          <div v-if="news[0]" class="middle-text mb-4 text-top">
            <table   width="100%">
              <tr>
                <td class="align-center   " width="100%">
                  <div>
                    <h1  class="  md-t" >{{news[0] ? news[0].title : ''}}</h1>

                  </div>
                </td>
              </tr>
            </table>
          </div>



          <!-- END CENTERED WHITE CONTAINER -->
        </div>

        <div v-if="news[0]" class="main-banner mb-4">
          <table   width="100%">
            <tr>
              <td class="align-center landing" width="100%">
                <div  class="landing__banner  " style="background-image: url(https://electionback.dlclients.xyz/storage/panels/images/WAsaPmR7XBpazqv9pRZ60Pb0js3XFQsGSa5269qa.jpg);"></div>
              </td>
            </tr>
          </table>
        </div>

        <div class="content">
          <!-- START CENTERED WHITE CONTAINER -->


          <div v-if="news[1]" class="  ">
            <table   width="100%">
              <tr>
                <td class="   row-news " width="100%">

                  <!---->
                  <div v-for="(item, index) in news.slice(1)"   v-if="index < 2" class="col-news">
                    <a class="event" href="#">
                      <div  class="event__image">
                        <div   style="overflow: hidden;">
                          <img  :src="getImagePath(item.featured_images[0].image)" >
                        </div>


                      </div>
                      <div   class="event__content">
                        <h2   class="event__content__title p"> {{ item.title }} </h2>
                        <a  target="_blank"  class="event__content__action" :href="getUrlPath(item.url)">للمزيد</a>
                      </div>

                    </a>

                  </div>
                  <!---->


                </td>
              </tr>
            </table>
          </div>
          <div v-if="news[3]" class="mb-4 ">
            <table   width="100%">
              <tr>
                <td class="   row-news " width="100%">
                  <!---->
                  <div v-for="(item, index) in news.slice(3)"   v-if="index < 2"  class="col-news">
                    <a class="event" href="#">
                      <div  class="event__image">
                        <div   style="overflow: hidden;">
                          <img  :src="getImagePath(item.featured_images[0].image)" >
                        </div>
                      </div>
                      <div   class="event__content">
                        <h2   class="event__content__title p"> {{ item.title }} </h2>
                        <a target="_blank"  class="event__content__action" :href="getUrlPath(item.url)">للمزيد</a>
                      </div>

                    </a>

                  </div>
                  <!---->
                </td>

              </tr>
            </table>
          </div>
          <div v-if="news[5]" class="middle-text mb-4">
            <table   width="100%">
              <tr>
                <td class="align-center   " width="100%">
                  <div>
                    <h1  class="  md-t" >{{news[5] ? news[5].title : ''}}</h1>

                  </div>
                </td>
              </tr>
            </table>
          </div>


          <!-- END CENTERED WHITE CONTAINER -->
        </div>
        <div v-if="news[5]" class="main-banner mb-4">
          <table   width="100%">
            <tr>
              <td class="align-center landing" width="100%">
                <a href="#" target="_blank">
                  <div  class="landing__banner  " style="background-image: url(https://electionback.dlclients.xyz/storage/panels/images/WAsaPmR7XBpazqv9pRZ60Pb0js3XFQsGSa5269qa.jpg);"></div>

                </a>
              </td>
            </tr>
          </table>
        </div>
        <div class="content">
          <!-- START CENTERED WHITE CONTAINER -->


          <div v-if="news[6]" class=" mb-4">
            <table   width="100%">
              <tr>
                <td class="   row-news " width="100%">

                  <!---->
                  <div v-for="(item, index) in news.slice(6)"   v-if="index < 2" class="col-news">
                    <a  target="_blank" class="event" href="#">
                      <div  class="event__image">
                        <div   style="overflow: hidden;">
                          <img  :src="getImagePath(item.featured_images[0].image)" >
                        </div>


                      </div>
                      <div   class="event__content">
                        <h2   class="event__content__title p">{{ item.title }} </h2>
                        <a   class="event__content__action" :href="getUrlPath(item.url)">للمزيد</a>
                      </div>

                    </a>

                  </div>
                  <!---->




                </td>

              </tr>
            </table>
          </div>

          <div v-if="news[8]" class="middle-text mb-4">
            <table   width="100%">
              <tr>
                <td class="align-center   " width="100%">
                  <div>
                    <h1  class="  md-t" >{{news[8] ? news[8].title : ''}}</h1>


                  </div>
                </td>
              </tr>
            </table>
          </div>


          <!-- END CENTERED WHITE CONTAINER -->
        </div>

        <div v-if="news[8]" class="main-banner mb-4">
          <table   width="100%">
            <tr>
              <td class="align-center landing" width="100%">
                <div  class="landing__banner  " style="background-image: url(https://electionback.dlclients.xyz/storage/panels/images/WAsaPmR7XBpazqv9pRZ60Pb0js3XFQsGSa5269qa.jpg);"></div>
              </td>
            </tr>
          </table>
        </div>
        <div class="content">
          <!-- START CENTERED WHITE CONTAINER -->


          <div v-if="news[9]" class="">
            <table   width="100%">
              <tr>
                <td class="   row-news " width="100%">

                  <!---->
                  <div v-for="(item, index) in news.slice(9)"   v-if="index < 2" class="col-news">
                    <a target="_blank" class="event" href="#">
                      <div  class="event__image">
                        <div   style="overflow: hidden;">
                          <img  :src="getImagePath(item.featured_images[0].image)" >
                        </div>


                      </div>
                      <div   class="event__content">
                        <h2   class="event__content__title p"> {{ item.title }} </h2>
                        <a   class="event__content__action" :href="getUrlPath(item.url)">للمزيد</a>
                      </div>

                    </a>

                  </div>
                  <!---->





                </td>

              </tr>
            </table>
          </div>

          <div v-if="news[11]" class="  mb-4">
            <table   width="100%">
              <tr>
                <td class="col-three-news " width="100%">
                  <a target="_blank" class="event" href="#">
                    <div  class="event__image">
                      <div  class="grid-row" style="overflow: hidden;">


                        <!---->
                        <div   class="grid-imgs-right  " >

                          <div v-if="news[11].gallery[0]"  class="grid-imgs__row-2 grid-img"  :style="{backgroundImage: 'url('+getImagePath(news[11].gallery[0].image)+')'}"></div>
                          <!---->
                          <div   class="grid-imgs__row-1">
                            <div v-if="news[11].gallery[1]"   class="grid-img" :style="{backgroundImage: 'url('+getImagePath(news[11].gallery[1].image)+')'}"">
                            </div>
                            <!---->
                            <div v-if="news[11].gallery[2]"   class="grid-img" :style="{backgroundImage: 'url('+getImagePath(news[11].gallery[2].image)+')'}" >

                            </div>
                            <!---->
                          </div>
                        </div>
                        <div v-if="news[11].gallery[3]"   class="grid-imgs-big grid-img" :style="{backgroundImage: 'url('+getImagePath(news[11].gallery[3].image)+')'}">

                        </div>

                      </div>


                    </div>
                    <div   class="event__content2">
                      <h2   class="event__content__title p"> {{ news[11].title }} </h2>
                      <a   class="event__content__action" :href="getUrlPath(news[11].url)">للمزيد</a>
                    </div>

                  </a>
                </td>
              </tr>
            </table>
          </div>


          <!-- END CENTERED WHITE CONTAINER -->
        </div>
        <!-- START FOOTER -->

        <!-- END FOOTER -->
        <div class="footer ">
          <table  >
            <tr>
              <td>
                <div class="end-sec ">
                  <div>
                    <a   class="logo" target="_blank" href="https://lebanesekataeb.com/">
                      <img  src="https://www.lebanesekataeb.com/assets/images/en/logo.png" width="200" height="37.09" alt="Kataeb logo white">
                    </a>
                  </div>



                  <div  class="social-icons  ">
                    <a   target="_blank" class="social-icons__item  " href="https://www.facebook.com/kataeb.org.official.page">
                      <svg  width="16" height="16" fill="#207f3e" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 21" enable-background="new 0 0 21 21" xml:space="preserve">
                                    <path  d="M11.354,4.707c0.079-0.078,0.347-0.26,1.126-0.26c0.434,0,0.906,0.043,1.401,0.128l0.77,0.133l0.665-3.723l-0.765-0.144
                                    c-2.472-0.466-4.455-0.303-5.657,0.465C8.121,1.799,7.57,2.46,7.256,3.272C7.037,3.85,6.931,4.666,6.931,5.764v0.787H4.629v3.524
                                    h2.302v10.853h4.164V10.076h2.997V6.552h-2.997V5.973C11.095,5.012,11.31,4.75,11.354,4.707z"></path>
                                  </svg>
                    </a>
                    <!---->
                    <a  target="_blank" class="social-icons__item  "  href="https://twitter.com/kataeb_Ar">
                      <svg width="16" height="16"  fill="#207f3e" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 21" enable-background="new 0 0 21 21" xml:space="preserve"><path   d="M15.105,14.226c0.377-0.076,1.123-0.316,2.031-1.076c1.383-1.157,2.786-4.285,1.847-7.973
                                  C18.7,4.063,17.956,2.41,17.956,2.41s-1.212,2.303-4.086,3.75c-1.3,0.655-2.32,1.008-3.084,1.2
                                  c-0.026-2.126-1.769-3.842-3.917-3.842c-2.155,0-3.902,1.727-3.918,3.862l-0.001,0L0.472,8.244L2.95,9.627
                                  c0,0-0.288,5.072,5.763,7.954c6.052,2.882,11.815-1.326,11.815-1.326S17.33,15.798,15.105,14.226z M5.93,7.66
                                  c-1.126,0-1.126-1.746,0-1.746S7.056,7.66,5.93,7.66z"></path>
                                  </svg>
                    </a>
                    <!---->
                    <a   target="_blank" class="social-icons__item  "  href="https://www.youtube.com/c/kataebNews">
                      <svg  width="16" height="16" fill="#207f3e" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 21" enable-background="new 0 0 21 21" xml:space="preserve">
                                  <path   d="M17.273,3.587H3.727c-1.541,0-2.791,1.25-2.791,2.791v8.243c0,1.541,1.25,2.791,2.791,2.791h13.547
                                    c1.541,0,2.791-1.25,2.791-2.791V6.379C20.064,4.837,18.815,3.587,17.273,3.587z M15.816,10.534c-0.005,0.19-0.066,0.374-0.177,0.53
                                    c-0.116,0.163-0.682,0.481-0.682,0.481l-6.044,3.247c-0.156,0.066-0.287,0.092-0.421,0.092c-0.365,0-0.701-0.195-0.877-0.509
                                    c-0.08-0.143-0.122-0.306-0.122-0.47l0.004-0.369L7.493,9.967l0.004-2.508L7.493,7.093c0-0.165,0.042-0.327,0.123-0.47
                                    c0.24-0.43,0.802-0.626,1.27-0.429c0,0,5.37,2.89,5.989,3.221c0.138,0.074,0.648,0.355,0.765,0.519
                                    c0.116,0.163,0.177,0.354,0.177,0.554L15.816,10.534z"></path>
                                  </svg>
                    </a>
                    <!---->
                    <a   target="_blank" class="social-icons__item  "  href="https://www.instagram.com/kataeb_news/">
                      <svg width="16" height="16" fill="#207f3e" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 21" enable-background="new 0 0 21 21" xml:space="preserve"><g  >
                                  <path   d="M15.778,1.912H5.222c-1.97,0-3.572,1.603-3.572,3.572v10.032c0,1.97,1.603,3.572,3.572,3.572h10.555
                                  c1.97,0,3.572-1.602,3.572-3.572V5.484C19.35,3.514,17.747,1.912,15.778,1.912z M17.788,15.516c0,1.109-0.902,2.011-2.011,2.011
                                  H5.222c-1.109,0-2.011-0.902-2.011-2.011V5.484c0-1.109,0.902-2.011,2.011-2.011h10.555c1.109,0,2.011,0.902,2.011,2.011V15.516z"></path><path _ngcontent-kataeb-c115="" d="M10.5,5.698c-2.648,0-4.802,2.154-4.802,4.803c0,2.648,2.154,4.802,4.802,4.802c2.648,0,4.803-2.154,4.803-4.802
                                  C15.303,7.852,13.148,5.698,10.5,5.698z M10.5,13.741c-1.787,0-3.241-1.454-3.241-3.241c0-1.787,1.454-3.241,3.241-3.241
                                  c1.787,0,3.241,1.454,3.241,3.241C13.741,12.287,12.287,13.741,10.5,13.741z"></path><circle  cx="15.278" cy="5.532" r="0.814"></circle></g>
                                </svg>
                    </a>
                    <!---->
                  </div>
                  <!---->


                </div>
              </td>
            </tr>

          </table>
        </div>





      </td>
      <!---->
    </tr>
  </table>
  </body>
  </html>
</template>
<script>

export default {
  components: {
    Notification
  },
  props: {
    transitionName: {
      type: String,
      default: 'list'
    },
    transitionMode: {
      type: String,
      default: 'in-out'
    },
    overlap: {
      type: Boolean,
      default: false
    },
    news: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  methods: {
    getImagePath(path) {
      return 'https://images.kataeb.org/' + path;
      // return process.env.VUE_APP_SERVER_IMAGE_URL + path;
    },
    getUrlPath(path) {
      return 'https://images.kataeb.org/' + path;
      // return process.env.VUE_APP_SERVER_IMAGE_URL + path;
    },
  },

};
</script>
<style>
/*All the styling goes here*/
.html{

  direction: rtl;
  margin: 0 auto;
}
.body {
  background-color: #eaebed;
  font-family: 'Droid Arabic Kufi';
  line-height: 1.4;
  margin: 0;
  padding: 0;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  direction: rtl;

}
table {
  border-collapse: separate;
  mso-table-lspace: 0pt;
  mso-table-rspace: 0pt;
  min-width: 100%;
  width: 100%; }

.v-img {
  width: 100%;
  object-fit: cover;
}
/* -------------------------------------
    BODY & CONTAINER
------------------------------------- */
.body .container {
  display: block;
  Margin: 0 auto !important;
  max-width: 700px;
  width: 100%;
  background-color: white;
}
.body .content {
  box-sizing: border-box;
  display: block;
  Margin: 0 auto;
  max-width: 700px;
  padding: 0 26px;
}

/* -------------------------------------
    HEADER, FOOTER, MAIN
------------------------------------- */
.camp {
  width: 100%;
  margin: 0 auto;
}
.a{
  text-decoration: none;
}
.main {
  background: #ffffff;
  width: 100%;
}

.header {
  padding: 20px 0;
}
.main-banner {
  overflow: hidden;
}

.footer {
  clear: both;
  text-align: center;
  width: 100%;
}
.sub-footer {
  clear: both;
  text-align: center;
  width: 100%;
}
.landing__campaign  {
  display: block;
  max-width: 1200px;
  margin: 60px auto!important;
  padding: 0;
}
.campaign__main {
  background-color: #fff;
  min-width: calc(65% - 16px);
  padding: 10px 10px 5px 10px;
  z-index: 10;
  box-shadow: 14px 13px 14px -2px #0000005c;
}
.video  {
  align-items: center;
  height: 100%;
  display: flex;
}
.video__frame  {
  flex: 1 1 calc(100% / 2);
  max-width: calc(100% / 2);
}


.video__content  {
  flex: 1 1 calc(100% / 3);
  padding-right: 30px;
  padding-top: 30px;
  text-align: right;
  max-width: calc(100% / 3);
}
.video__content__title  {
  font-family: 'Droid Arabic Kufi';
  margin: 0 0 15px;
  font-weight: 600;
}
.video__content__button  {
  padding: 12px 32px;
  font-size: 1em;
  background-color: #207f3e;
  color: #fff;
  border: none;
  cursor: pointer;
}
.main-title {
  display: flex;
  align-items: baseline;
  width: 100%;
  margin: 30px 0;
}
.landing__banner {
  min-height: 368px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.banner__item {
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.banner {
  overflow: hidden;
  position: relative;
  padding-bottom: 55%;
}

.row-news {
  display: flex;
  width: 96%;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: row;
}
.col-three-news{
  display: block;
  flex: 1 1 100%;
  max-width: 96%;
  padding: 0;
  margin: 0 auto;
}
.col-news {
  display: block;
  max-width: 49%;
  margin: 0 auto;

}
.event {
  display: block;
  text-decoration: none;
  color: unset;
  text-align: right;
}
.event__image {
  position: relative;
}
.event__image img{
  width: 100%;
  height: 25vh;
  object-fit: cover;}
.date  {
  float: left;
  width: 50px;
  color: #fff;
  margin-bottom: 10px;
  margin-left: 10px;
  text-align: center;
}
.date__month  {
  background-color: #207f3e;
  font-size: 1em;
  padding: 6px 0;
}
.date__day {
  margin-top: 4px;
  background-color: #207f3e;
  font-size: 2em;
  font-weight: 800;
  padding: 8px 0;
}
.event__content{
  padding: 10px;
  height: 20vh;
  background-color: rgb(248,246,246);}
.event__content2 {
  padding: 10px;
  overflow: hidden;
  margin: 2px;
  background-color: #f8f6f6;

}
.event__content__title  {

  margin: 4px 0 18px 0;
}
.event__content__action  {
  display: inline-block;
  text-decoration: none;
  padding: 6px 12px;
  font-size: .75;
  background-color: #207f3e;
  color: #fff;
  border: none;
  cursor: pointer;
}
.grid-row  {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.grid-img  {
  background-size: cover;
  background-position: center;
  padding-bottom: 40%;
  margin: 3px;
}
.grid-imgs-right  {
  width: 50%;
}
.grid-imgs__row-1  {
  display: flex;
}

.grid-imgs-big  {
  height: inherit;
  width: 48%;

}
.grid-imgs__row-1 .grid-img  {
  width: 50%;
}
.end-sec {

  align-items: center;
  padding: 36px 0;
}

.social-icons {
  display: inline-flex;
  margin-bottom: 24px  ;
}
.social-icons__item svg {
  height: 15px;
  width: auto;
  vertical-align: middle;
  margin: 0.5rem;
}
.develop  a  {
  color: #fff;
  font-weight: 600;
  text-decoration: underline;
}
/* -------------------------------------
    TYPOGRAPHY
------------------------------------- */
.bg-t {
  margin-right: 16px;
  margin-left: 0;
  font-size: 32px;
  font-weight: 700;
}
.md-t{
  font-size: 25px;
  line-height: 1.5em;
  font-weight: 600;
}
.p{
  font-size: 17px;
  line-height: 1.2em;

}
.sm-t{
  font-size: 15px;
}
.xs-t{
  font-size: 12px;
  line-height: 2.3;
}
.date{
  font-size: 10px;
}
.black{
  color: black;
}
.bold{
  font-weight: bolder;
}
.grey{
  color: #999999;
}
.white{
  color: white;
}
.mb-4{
  margin-bottom: 60px;
}
.m-0{
  margin: 0;
}
.p-2{
  padding: 40px 20px 20px 20px;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}
.align-center {
  text-align: center;
}


@media only screen and (max-width: 620px) {

  table[class=body] h1 {
    font-size: 13px !important;
    margin-bottom: 10px !important;
  }
  table[class=body] p,
  table[class=body] ul,
  table[class=body] ol,
  table[class=body] td,
  table[class=body] span,
  table[class=body] a {
    font-size: 12px !important;
  }
  table[class=body] .wrapper,
  table[class=body] .article {
    padding: 10px !important;
  }
  table[class=body] .content {

    padding: 7px !important;

  }
  table[class=body] .container {
    padding: 0 !important;
    width: 100% !important;
    display: block!important;

    background-color: #ffffff!important;
    Margin: 0 auto!important;
  }
  table[class=body] .main {
    border-left-width: 0 !important;
    border-radius: 0 !important;
    border-right-width: 0 !important;
  }
  .landing__banner {
    min-height: 200px!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    overflow: hidden!important;
  }


  .md-t {
    font-size: 15px!important;
    line-height: 1.5em!important;
    font-weight: 600!important;
  }


  .campaign__main {
    min-width: 31%!important;
    padding: 18px 0!important;
    z-index: 10!important;
    margin: 0 auto!important;
    width: 90%!important;
  }
  .video__content {
    flex: unset;
    padding-right: 30px!important;
    text-align: right!important;
    padding-top: 30px!important;
    max-width: 100%!important;
  }
  .video__frame {
    flex: unset!important;
    max-width: 100%!important;
  }
  .video {
    align-items: center!important;
    height: 100%!important;
    display: block!important;
  }
  .col-news {
    display: block;
    flex: unset!important;
    max-width: 100%!important;
    padding: 14px!important;
  }
  .row-news {
    display: block!important;
    margin: 0 auto!important;
    flex-wrap: wrap!important;
    flex-direction: column!important;
  }
  .col-three-news {
    display: block!important;
    flex: 1 1 100%!important;
    max-width: 100%!important;
    padding: 0!important;
    width: 93.4%!important;
    margin: 0 auto!important;
  }
  .grid-imgs-right {
    width: 48%!important;
  }
}


@media all {
  .ExternalClass {
    width: 100%;
  }
  .ExternalClass,
  .ExternalClass p,
  .ExternalClass span,
  .ExternalClass font,
  .ExternalClass td,
  .ExternalClass div {
    line-height: 100%;
  }

}
.vs__dropdown-menu{
  display: block !important;
}
.el-dialog--center .el-dialog__footer {
  text-align: right !important;
}
.style-chooser .vs__selected {
  border-radius: 3px !important;
  border-color: #23CCEF !important;
  background-color: white !important;
  border-radius: 2px !important;
  color: #23CCEF !important;
}

.style-chooser .vs__deselect {
  background-color: transparent !important;
  color: #23CCEF !important;
  opacity: 1 !important;
  font-size: 12px !important;
}

.style-chooser .vs__dropdown-option--selected {
  color: #23CCEF !important;
}

.style-chooser .prev-next {
  margin-top: 15px !important;
}

.style-chooser .prev-next button {
  margin-right: 15px !important;
  margin-left: 10px !important;
}

</style>
