<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Email Template List</h4>
          <div class="d-flex justify-content-start">
            <div class="text-center pt-1 px-1">
              <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['email-campaigns/create'])"
                           :to="'/email-campaigns/create'"
                           class="btn btn-success btn-wd mr-2">
                Create Campaign
                <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
              </router-link>
              <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['email-templates/create'])"
                           :to="'/email-templates/create'"
                           class="btn btn-info btn-wd">
                Add New
                <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
              </router-link>



            </div>
          </div>
        </div>

        <div>
          <general-data-table
            ref="table"
            :searchable="false"
            :method="'POST'"
            :api-url="'email-templates/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">


              <template slot-scope="scope">
                <div v-if="column.value === 'check'">
                  <l-switch
                    v-model="scope.row[column.value]">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

                <span v-else>{{ scope.row[column.value] }}</span>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['email-templates/update'])"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/email-templates/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i></router-link>
                <a v-if="$store.getters['auth/checkAccess']('email-templates/delete')" v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this template?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
      <send-email-modal :visible="sendEmailModalVisibility"
                        :subscribers="subscribers"
                        :templateId="toSendEmailId"
                        @close="closeSendEmailModal()"
                        @confirm="confirmSendEmailModal()">
      </send-email-modal>
    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import ExportModal from '@/components/ExportModal/ExportModal.vue';
import SendEmailModal from "../../../components/SendEmailModal/SendEmailModal";

export default {
  components: {
    SendEmailModal,
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: 'ID', value: 'id', minWidth: '50', align: 'center'},
        {label: 'Name', value: 'name', minWidth: '50', align: 'center'},
        {label: 'Date', value: 'date_created', minWidth: '200', align: 'center'},
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      deleteModalVisibility: false,
      toDeleteId: undefined,
      sendEmailModalVisibility: false,
      toSendEmailId: 0,
      url : process.env.VUE_APP_SERVER_IMAGE_URL,
      subscribers: []
    }

  },mounted() {
    this.axios.post("email-templates/builder").then((response) => {
      this.subscribers = response.data.members;
    }).catch((error) => {
      console.log(error)
      if (error.response.status === 404) {
        this.entityNotFoundError = true;
        this.$notify({
          message: "Failed to connect to mailchimp",
          timeout: 2000,
          type: 'danger'
        });

      } else {
        console.error(error);
      }
    })
  },
  methods: {
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },
    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("email-templates/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Template deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
    openSendEmailModal(id) {
      this.sendEmailModalVisibility = true;
      this.toSendEmailId = id;
    },
    closeSendEmailModal() {
      this.sendEmailModalVisibility = false;
    },
    confirmSendEmailModal() {

      let data = {
        'id': this.toDeleteId,
      }
      this.axios.delete("email-templates/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Template deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
  },
}
</script>

