<template>


  <!DOCTYPE html>
  <html>

  <head>
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <!-- Google Fonts -->
    <link href="https://fonts.googleapis.com/earlyaccess/droidarabickufi.css" rel="stylesheet">

  </head>

  <body style="margin: 0;">
  <table class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; background-color: #eaebed; font-family: 'Droid Arabic Kufi'; line-height: 1.4; margin: 0 auto; padding: 0; width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; direction: rtl;" width="100%" bgcolor="#eaebed">
    <tr>
      <td class="container" style="display: block; max-width: 600px; width: 100%; margin: 0 auto; background-color: white; Margin: 0 auto;" width="100%" bgcolor="white">
        <div class="header" style="padding: 20px 0;">
          <div width="100%">
            <div class="align-center" width="100%" style="text-align: center;">
              <a target="_blank" href="https://lebanesekataeb.com/"><img src="https://www.lebanesekataeb.com/assets/images/en/logo.png" width="200" alt="Postdrop"></a>
            </div>
          </div>
        </div>
        <div class="main-banner" style="overflow: hidden;">
          <div width="100%">
            <div class="align-center landing" width="100%" style="text-align: center;">
              <div class="landing__banner  " style="min-height: 368px; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden; background-image: url(https://electionback.dlclients.xyz/storage/panels/images/WAsaPmR7XBpazqv9pRZ60Pb0js3XFQsGSa5269qa.jpg);"></div>
            </div>
          </div>
        </div>
        <div class="content" style="box-sizing: border-box; display: block; Margin: 0 auto; max-width: 600px; padding: 0 26px;">
          <div class="camp" style="width: 100%; margin: 0 auto;">
            <div width="100%">
              <div class="align-center  landing__campaign " width="100%" style="display: block; max-width: 1200px; padding: 0; text-align: center; margin: 60px auto;">
                <div class="campaign__main" style="background-color: #fff; min-width: calc(65% - 16px); padding: 10px 10px 5px 10px; z-index: 10; box-shadow: 14px 13px 14px -2px #0000005c;">
                  <a href="#" target="_blank" class="a" style="text-decoration: none;">
                    <div class="video" style="align-items: center; height: 100%; display: flex;">
                      <div class="video__frame" style="flex: 1 1 calc(100% / 2); max-width: calc(100% / 2);">
                        <img class="v-img" src="https://electionback.dlclients.xyz/storage/panels/images/WAsaPmR7XBpazqv9pRZ60Pb0js3XFQsGSa5269qa.jpg" height="230" alt="Postdrop" style="width: 100%; object-fit: cover;">

                      </div>
                      <div class="video__content" style="flex: 1 1 calc(100% / 3); padding-right: 30px; padding-top: 30px; text-align: right; max-width: calc(100% / 3);"><h2 class="video__content__title md-t black" style="font-family: 'Droid Arabic Kufi'; margin: 0 0 15px; font-size: 25px; line-height: 1.5em; font-weight: 600; color: black;">الحملة الانتخابية لحزب الكتائب اللبنانية</h2>
                        <h1 class="video__content__button" tabindex="0" style="padding: 12px; text-align: center; width: 30%; font-size: 1em; background-color: #207f3e; color: #fff; border: none; cursor: pointer;">الحملة</h1>

                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="news[0]" class="middle-text mb-4 text-top" style="margin-bottom: 60px;">
            <div width="100%">
              <div class="align-center " width="100%" style="text-align: center;">
                <div>
                  <h1 class="  md-t" style="font-size: 25px; line-height: 1.5em; font-weight: 600;">{{ news[0].title }}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="news[0]" class="main-banner mb-4" style="overflow: hidden; margin-bottom: 60px;">
          <div width="100%">
            <div class="align-center landing" width="100%" style="text-align: center;">
              <div class="landing__banner" :style="{backgroundImage: 'url('+getImagePath(news[0].featured_images[0].image)+')'}" style="min-height: 368px; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden;"></div>
            </div>
          </div>
        </div>
        <div class="content" style="box-sizing: border-box; display: block; Margin: 0 auto; max-width: 600px; padding: 0 26px;">
          <div width="100%">
            <div class="row-news" width="100%" style="display: flex; width: 96%; margin: 0 auto; flex-wrap: wrap; flex-direction: row;">
              <a v-if="news[1]" class=" col-news event" :href="getUrlPath(news[1].url)" style="max-width: 49%; margin: 0 auto; display: block; text-decoration: none; color: unset; text-align: right;">
                <div class="event__image" style="position: relative;">
                  <div style="overflow: hidden;">
                    <img :src="getImagePath(news[0].featured_images[0].image)" style="width: 100%;
    height: 25vh;object-fit: cover">
                  </div>
                </div>
                <div class="event__content" style=" padding: 10px;
    background-color: rgb(248,246,246);">
                  <h2 class="event__content__title p" style="  margin: 4px 0 18px 0;
  width: 230px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap; font-size: 17px; line-height: 1.2em;"> {{ news[1].title }} </h2>
                  <h1 class="event__content__action" style="display: inline-block; text-decoration: none; padding: 6px 12px; font-size: 12px; background-color: #207f3e; color: #fff; border: none; cursor: pointer;">للمزيد</h1>
                </div>

              </a>



              <a v-if="news[2]" target="_blank" class="event col-news" :href="getUrlPath(news[2].url)" style="max-width: 49%; margin: 0 auto; display: block; text-decoration: none; color: unset; text-align: right;">
                <div class="event__image" style="position: relative;">
                  <div style="overflow: hidden;">
                    <img :src="getImagePath(news[2].featured_images[0].image)" style="width: 100%;
    height: 25vh;object-fit: cover">
                  </div>
                </div>
                <div class="event__content" style=" padding: 10px;
    background-color: rgb(248,246,246);">
                  <h2 class="event__content__title p" style="  margin: 4px 0 18px 0;
  width: 230px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap; font-size: 17px; line-height: 1.2em;"> {{ news[2].title}} </h2>
                  <h1 class="event__content__action" style="display: inline-block; text-decoration: none; padding: 6px 12px; font-size: 12px; background-color: #207f3e; color: #fff; border: none; cursor: pointer;">للمزيد</h1>
                </div>
              </a>


            </div>
          </div>

          <div class="mb-4" width="100%" style="margin-bottom: 60px;">
            <div class="row-news" width="100%" style="display: flex; width: 96%; margin: 0 auto; flex-wrap: wrap; flex-direction: row;">

              <a v-if="news[3]" class="event col-news" :href="getUrlPath(news[3].url)" style="max-width: 49%; margin: 0 auto; display: block; text-decoration: none; color: unset; text-align: right;">
                <div class="event__image" style="position: relative;">
                  <div style="overflow: hidden;">
                    <img :src="getImagePath(news[3].featured_images[0].image)" style="width: 100%;
    height: 25vh;object-fit: cover">
                  </div>
                </div>
                <div class="event__content" style=" padding: 10px;
    background-color: rgb(248,246,246);">
                  <h2 class="event__content__title p" style="  margin: 4px 0 18px 0;
  width: 230px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap; font-size: 17px; line-height: 1.2em;">{{ news[3].title}} </h2>
                  <h1 class="event__content__action" style="display: inline-block; text-decoration: none; padding: 6px 12px; font-size: 12px; background-color: #207f3e; color: #fff; border: none; cursor: pointer;">للمزيد</h1>
                </div>
              </a>


              <a v-if="news[4]" :href="getUrlPath(news[4].url)" target="_blank" class="event col-news" style="max-width: 49%; margin: 0 auto; display: block; text-decoration: none; color: unset; text-align: right;">
                <div class="event__image" style="position: relative;">
                  <div style="overflow: hidden;">
                    <img :src="getImagePath(news[4].featured_images[0].image)" style="width: 100%;
    height: 25vh;object-fit: cover">
                  </div>
                </div>
                <div class="event__content" style=" padding: 10px;
    background-color: rgb(248,246,246);">
                  <h2 class="event__content__title p" style="  margin: 4px 0 18px 0;
  width: 230px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap; font-size: 17px; line-height: 1.2em;"> {{ news[4].title}} </h2>
                  <h1 class="event__content__action" style="display: inline-block; text-decoration: none; padding: 6px 12px; font-size: 12px; background-color: #207f3e; color: #fff; border: none; cursor: pointer;">للمزيد</h1>
                </div>
              </a>

            </div>
          </div>
          <div v-if="news[5]" class="middle-text mb-4" style="margin-bottom: 60px;">
            <div width="100%">
              <div class="align-center" width="100%" style="text-align: center;">
                <div>
                  <h1 class="  md-t" style="font-size: 25px; line-height: 1.5em; font-weight: 600;">{{ news[5].title }}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="news[5]" class="main-banner mb-4" style="overflow: hidden; margin-bottom: 60px;">
          <div width="100%">
            <div class="align-center landing" width="100%" style="text-align: center;">
              <a href="#" target="_blank">
                <div class="landing__banner" :style="{backgroundImage: 'url('+getImagePath(news[5].featured_images[0].image)+')'}" style="min-height: 368px; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden;"></div>
              </a>
            </div>
          </div>
        </div>
        <div class="content" style="box-sizing: border-box; display: block; Margin: 0 auto; max-width: 600px; padding: 0 26px;">
          <div class="mb-4" width="100%" style="margin-bottom: 60px;">
            <div class="row-news" width="100%" style="display: flex; width: 96%; margin: 0 auto; flex-wrap: wrap; flex-direction: row;">

              <a v-if="news[6]" target="_blank" class="event col-news" :href="getUrlPath(news[6].url)" style="max-width: 49%; margin: 0 auto; display: block; text-decoration: none; color: unset; text-align: right;">
                <div class="event__image" style="position: relative;">
                  <div style="overflow: hidden;">
                    <img :src="getImagePath(news[6].featured_images[0].image)" style="width: 100%;
    height: 25vh;object-fit: cover">
                  </div>
                </div>
                <div class="event__content" style=" padding: 10px;
    background-color: rgb(248,246,246);">
                  <h2 class="event__content__title p" style="  margin: 4px 0 18px 0;
  width: 230px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap; font-size: 17px; line-height: 1.2em;"> {{ news[6].title }} </h2>
                  <h1 class="event__content__action" style="display: inline-block; text-decoration: none; padding: 6px 12px; font-size: 12px; background-color: #207f3e; color: #fff; border: none; cursor: pointer;">للمزيد</h1>
                </div>
              </a>


              <a v-if="news[7]" target="_blank" class="event col-news" :href="getUrlPath(news[7].url)" style="max-width: 49%; margin: 0 auto; display: block; text-decoration: none; color: unset; text-align: right;">
                <div class="event__image" style="position: relative;">
                  <div style="overflow: hidden;">
                    <img :src="getImagePath(news[7].featured_images[0].image)" style="width: 100%;
    height: 25vh;object-fit: cover">
                  </div>
                </div>
                <div class="event__content" style=" padding: 10px;
    background-color: rgb(248,246,246);">
                  <h2 class="event__content__title p" style="  margin: 4px 0 18px 0;
  width: 230px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap; font-size: 17px; line-height: 1.2em;"> {{ news[7].title }} </h2>
                  <h1 class="event__content__action" style="display: inline-block; text-decoration: none; padding: 6px 12px; font-size: 12px; background-color: #207f3e; color: #fff; border: none; cursor: pointer;">للمزيد</h1>
                </div>
              </a>

            </div>
          </div>
          <div v-if="news[8]" class="middle-text mb-4" style="margin-bottom: 60px;">
            <div width="100%">
              <div class="align-center" width="100%" style="text-align: center;">
                <h1 class="  md-t" style="font-size: 25px; line-height: 1.5em; font-weight: 600;">{{ news[8].title}}</h1>
              </div>
            </div>
          </div>
        </div>
        <div v-if="news[8]" class="main-banner " style="overflow: hidden;">
          <div class="mb-4" width="100%" style="margin-bottom: 60px;">
            <div class="align-center landing" width="100%" style="text-align: center;">
              <div class="landing__banner  " :style="{backgroundImage: 'url('+getImagePath(news[8].featured_images[0].image)+')'}" style="min-height: 368px; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden;"></div>
            </div>
          </div>
        </div>
        <div class="content" style="box-sizing: border-box; display: block; Margin: 0 auto; max-width: 600px; padding: 0 26px;">
          <div width="100%">
            <div class="row-news" width="100%" style="display: flex; width: 96%; margin: 0 auto; flex-wrap: wrap; flex-direction: row;">

              <a v-if="news[9]" target="_blank" class="event col-news" :href="getUrlPath(news[9].title)" style="max-width: 49%; margin: 0 auto; display: block; text-decoration: none; color: unset; text-align: right;">
                <div class="event__image" style="position: relative;">
                  <div style="overflow: hidden;">
                    <img :src="getImagePath(news[9].featured_images[0].image)" style="width: 100%;
    height: 25vh;object-fit: cover">
                  </div>
                </div>
                <div class="event__content" style=" padding: 10px;
    background-color: rgb(248,246,246);">
                  <h2 class="event__content__title p" style="  margin: 4px 0 18px 0;
  width: 230px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;font-size: 17px; line-height: 1.2em;"> {{ news[9].title }} </h2>
                  <h1 class="event__content__action" style="display: inline-block; text-decoration: none; padding: 6px 12px; font-size: 12px; background-color: #207f3e; color: #fff; border: none; cursor: pointer;">للمزيد</h1>
                </div>
              </a>


              <a v-if="news[10]" target="_blank" class="event col-news" :href="getUrlPath(news[10].url)" style="max-width: 49%; margin: 0 auto; display: block; text-decoration: none; color: unset; text-align: right;">
                <div class="event__image" style="position: relative;">
                  <div style="overflow: hidden;">
                    <img :src="getImagePath(news[10].featured_images[0].image)" style="width: 100%;
    height: 25vh;object-fit: cover">
                  </div>
                </div>
                <div class="event__content" style=" padding: 10px;
    background-color: rgb(248,246,246);">
                  <h2 class="event__content__title p" style="  margin: 4px 0 18px 0;
  width: 230px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap; font-size: 17px; line-height: 1.2em;"> {{ news[10].title }} </h2>
                  <h1 class="event__content__action" style="display: inline-block; text-decoration: none; padding: 6px 12px; font-size: 12px; background-color: #207f3e; color: #fff; border: none; cursor: pointer;">للمزيد</h1>
                </div>
              </a>

            </div>
          </div>
          <div class="mb-4" width="100%" style="margin-bottom: 60px;">
            <div class="col-three-news " width="100%" style="display: block; flex: 1 1 100%; max-width: 96%; padding: 0; margin: 0 auto;">
              <a v-if="news[11]" target="_blank" class="event" :href="getUrlPath(news[11].url)" style="display: block; text-decoration: none; color: unset; text-align: right;">
                <div class="event__image" style="position: relative;">
                  <div class="grid-row" style="display: flex; flex-direction: row; flex-wrap: wrap; overflow: hidden;">
                    <div class="grid-imgs-right  " style="width: 50%;">
                      <div v-if="news[11].gallery[0]" class="grid-imgs__row-2 grid-img" :style="{backgroundImage: 'url('+getImagePath(news[11].gallery[0].image)+')'}" style="background-size: cover; background-position: center; padding-bottom: 40%; margin: 3px;"></div>
                      <div class="grid-imgs__row-1" style="display: flex;">
                        <div v-if="news[11].gallery[1]" class="grid-img" :style="{backgroundImage: 'url('+getImagePath(news[11].gallery[1].image)+')'}" style="background-size: cover; background-position: center; padding-bottom: 40%; margin: 3px; width: 50%;">
                        </div>
                        <div v-if="news[11].gallery[2]" class="grid-img" :style="{backgroundImage: 'url('+getImagePath(news[11].gallery[2].image)+')'}" style="background-size: cover; background-position: center; padding-bottom: 40%; margin: 3px; width: 50%;">
                        </div>
                      </div>
                    </div>
                    <div v-if="news[11].featured_images[0]" class="grid-imgs-big grid-img" :style="{backgroundImage: 'url('+getImagePath(news[11].featured_images[0].image)+')'}" style="background-size: cover; background-position: center; padding-bottom: 40%; margin: 3px; height: inherit; width: 48%;">
                    </div>
                  </div>
                </div>
                <div class="event__content2" style="padding: 10px; overflow: hidden; margin: 2px; background-color: #f8f6f6;">
                  <h2 class="event__content__title p" style="  margin: 4px 0 18px 0;
  width: 230px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap; font-size: 17px; line-height: 1.2em;"> {{ news[11].title }} </h2>
                  <h1 class="event__content__action" style="display: inline-block; text-decoration: none; padding: 6px 12px; font-size: 12px; background-color: #207f3e; color: #fff; border: none; cursor: pointer;">للمزيد</h1>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="footer" style="clear: both; text-align: center; width: 100%;">
          <div>
            <div>
              <div>
                <div class="end-sec" style="align-items: center; padding: 36px 0;">
                  <div>
                    <a class="logo" target="_blank" href="https://lebanesekataeb.com/">
                      <img src="https://www.lebanesekataeb.com/assets/images/en/logo.png" width="200" height="37.09" alt="Kataeb logo white">
                    </a>
                  </div>
                  <div class="social-icons  " style="display: inline-flex; margin-bottom: 24px;">
                    <a target="_blank" class="social-icons__item  " href="https://www.facebook.com/kataeb.org.official.page">
                      <svg width="16" height="16" fill="#207f3e" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 21" enable-background="new 0 0 21 21" xml:space="preserve" style="height: 15px; width: auto; vertical-align: middle; margin: 0.5rem;">
                                  <path d="M11.354,4.707c0.079-0.078,0.347-0.26,1.126-0.26c0.434,0,0.906,0.043,1.401,0.128l0.77,0.133l0.665-3.723l-0.765-0.144
                                  c-2.472-0.466-4.455-0.303-5.657,0.465C8.121,1.799,7.57,2.46,7.256,3.272C7.037,3.85,6.931,4.666,6.931,5.764v0.787H4.629v3.524
                                  h2.302v10.853h4.164V10.076h2.997V6.552h-2.997V5.973C11.095,5.012,11.31,4.75,11.354,4.707z"/>
                                </svg>
                    </a>

                    <a target="_blank" class="social-icons__item  " href="https://twitter.com/kataeb_Ar">
                      <svg width="16" height="16" fill="#207f3e" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 21" enable-background="new 0 0 21 21" xml:space="preserve" style="height: 15px; width: auto; vertical-align: middle; margin: 0.5rem;"><path d="M15.105,14.226c0.377-0.076,1.123-0.316,2.031-1.076c1.383-1.157,2.786-4.285,1.847-7.973
                                C18.7,4.063,17.956,2.41,17.956,2.41s-1.212,2.303-4.086,3.75c-1.3,0.655-2.32,1.008-3.084,1.2
                                c-0.026-2.126-1.769-3.842-3.917-3.842c-2.155,0-3.902,1.727-3.918,3.862l-0.001,0L0.472,8.244L2.95,9.627
                                c0,0-0.288,5.072,5.763,7.954c6.052,2.882,11.815-1.326,11.815-1.326S17.33,15.798,15.105,14.226z M5.93,7.66
                                c-1.126,0-1.126-1.746,0-1.746S7.056,7.66,5.93,7.66z"/>
                                </svg>
                    </a>

                    <a target="_blank" class="social-icons__item  " href="https://www.youtube.com/c/kataebNews">
                      <svg width="16" height="16" fill="#207f3e" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 21" enable-background="new 0 0 21 21" xml:space="preserve" style="height: 15px; width: auto; vertical-align: middle; margin: 0.5rem;">
                                <path d="M17.273,3.587H3.727c-1.541,0-2.791,1.25-2.791,2.791v8.243c0,1.541,1.25,2.791,2.791,2.791h13.547
                                  c1.541,0,2.791-1.25,2.791-2.791V6.379C20.064,4.837,18.815,3.587,17.273,3.587z M15.816,10.534c-0.005,0.19-0.066,0.374-0.177,0.53
                                  c-0.116,0.163-0.682,0.481-0.682,0.481l-6.044,3.247c-0.156,0.066-0.287,0.092-0.421,0.092c-0.365,0-0.701-0.195-0.877-0.509
                                  c-0.08-0.143-0.122-0.306-0.122-0.47l0.004-0.369L7.493,9.967l0.004-2.508L7.493,7.093c0-0.165,0.042-0.327,0.123-0.47
                                  c0.24-0.43,0.802-0.626,1.27-0.429c0,0,5.37,2.89,5.989,3.221c0.138,0.074,0.648,0.355,0.765,0.519
                                  c0.116,0.163,0.177,0.354,0.177,0.554L15.816,10.534z"/>
                                </svg>
                    </a>

                    <a target="_blank" class="social-icons__item  " href="https://www.instagram.com/kataeb_news/">
                      <svg width="16" height="16" fill="#207f3e" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 21 21" enable-background="new 0 0 21 21" xml:space="preserve" style="height: 15px; width: auto; vertical-align: middle; margin: 0.5rem;"><g>
                                <path d="M15.778,1.912H5.222c-1.97,0-3.572,1.603-3.572,3.572v10.032c0,1.97,1.603,3.572,3.572,3.572h10.555
                                c1.97,0,3.572-1.602,3.572-3.572V5.484C19.35,3.514,17.747,1.912,15.778,1.912z M17.788,15.516c0,1.109-0.902,2.011-2.011,2.011
                                H5.222c-1.109,0-2.011-0.902-2.011-2.011V5.484c0-1.109,0.902-2.011,2.011-2.011h10.555c1.109,0,2.011,0.902,2.011,2.011V15.516z"/><path _ngcontent-kataeb-c115="" d="M10.5,5.698c-2.648,0-4.802,2.154-4.802,4.803c0,2.648,2.154,4.802,4.802,4.802c2.648,0,4.803-2.154,4.803-4.802
                                C15.303,7.852,13.148,5.698,10.5,5.698z M10.5,13.741c-1.787,0-3.241-1.454-3.241-3.241c0-1.787,1.454-3.241,3.241-3.241
                                c1.787,0,3.241,1.454,3.241,3.241C13.741,12.287,12.287,13.741,10.5,13.741z"/><circle cx="15.278" cy="5.532" r="0.814"/></g>
                              </svg>
                    </a>
                  </div>



                </div>
              </div>
            </div>

          </div>

        </div>






      </td>

    </tr>
  </table>
  </body>
  </html>
</template>
<script>

export default {
  components: {
    Notification
  },
  props: {
    transitionName: {
      type: String,
      default: 'list'
    },
    transitionMode: {
      type: String,
      default: 'in-out'
    },
    overlap: {
      type: Boolean,
      default: false
    },
    news: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  methods: {
    getImagePath(path) {
      return 'https://images.kataeb.org/' + path;
      // return process.env.VUE_APP_SERVER_IMAGE_URL + path;
    },
    getUrlPath(path) {
      return 'https://www.kataeb.org/' + path;
      // return process.env.VUE_APP_SERVER_IMAGE_URL + path;
    },
  },

};
</script>
<style>
@media only screen and (max-width: 620px) {
  table[class=body] h1 {
    font-size: 13px !important;
    margin-bottom: 10px !important;
  }

  table[class=body] p,
  table[class=body] ul,
  table[class=body] ol,
  table[class=body] td,
  table[class=body] span,
  table[class=body] a {
    font-size: 12px !important;
  }

  table[class=body] .wrapper,
  table[class=body] .article {
    padding: 10px !important;
    width: 100%;
    height: 25vh;
    object-fit: cover;
  }

  table[class=body] .content {
    padding: 7px !important;
  }

  table[class=body] .container {
    padding: 0 !important;
    width: 100% !important;
    display: block!important;
    background-color: #ffffff!important;
    Margin: 0 auto!important;
  }

  table[class=body] .main {
    border-left-width: 0 !important;
    border-radius: 0 !important;
    border-right-width: 0 !important;
  }

  .landing__banner {
    min-height: 200px!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    overflow: hidden!important;
  }

  .md-t {
    font-size: 15px!important;
    line-height: 1.5em!important;
    font-weight: 600!important;
  }

  .campaign__main {
    min-width: 31%!important;
    padding: 18px 0!important;
    z-index: 10!important;
    margin: 0 auto!important;
    width: 90%!important;
  }

  .video__content {
    flex: unset;
    padding-right: 30px!important;
    text-align: right!important;
    padding-top: 30px!important;
    max-width: 100%!important;
  }

  .video__frame {
    flex: unset!important;
    max-width: 100%!important;
  }

  .video {
    align-items: center!important;
    height: 100%!important;
    display: block!important;
  }

  .col-news {
    display: block;
    flex: unset!important;
    max-width: 100%!important;
    padding: 14px!important;
  }

  .row-news {
    display: block!important;
    margin: 0 auto!important;
    flex-wrap: wrap!important;
    flex-direction: column!important;
  }

  .col-three-news {
    display: block!important;
    flex: 1 1 100%!important;
    max-width: 100%!important;
    padding: 0!important;
    width: 93.4%!important;
    margin: 0 auto!important;
  }

  .grid-imgs-right {
    width: 48%!important;
  }
}
@media all {
  .ExternalClass {
    width: 100%;
  }

  .ExternalClass,
  .ExternalClass p,
  .ExternalClass span,
  .ExternalClass font,
  .ExternalClass td,
  .ExternalClass div {
    line-height: 100%;
  }
}
</style>
