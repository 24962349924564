<template>

  <div class="row" ref="emailTemplateForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
               <div class="row">
                 <div class="col-md-12">
                   <ValidationProvider
                           vid="name"
                           rules="required"
                           name="The Name"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="name"
                               name="name"
                               fou
                               v-model="formData.name">
                     </fg-input>
                   </ValidationProvider>
                 </div>
               </div>
                <div class="row">
                  <div class="col-md-12">
                    <fg-select
                      name="template_type"
                      size="large"
                      filterable
                      clearable
                      placeholder="Select Type"
                      :input-classes="'select-default'"
                      :label="'Type'"
                      :list="templateType"
                      :listItemLabel="'title'"
                      :listItemValue="'value'"
                      @change="changeSelect"
                      v-model="formData.template_type">
                    </fg-select>
                  </div>
                </div>

                <div :key="selectTypeKey" v-if="formData.template_type === 'ARTICLE'" class="row">
                  <div class="col-md-12">
                    <editor
                      v-model="formData.content"
                      :api-key="editorKey"
                      :init='editorConfig'
                    />
                  </div>
                </div>
                <div :key="selectTypeKey" v-if="formData.template_type === 'NEWS'" class="row">
                  <div class="col-md-12">
                    <v-select
                      :multiple="true"
                      :label="'title'"
                      class="style-chooser"
                      @option:deselected="selectChanged"
                      @option:selected="selectChanged"
                      :key="selectKey"
                      v-model="formData.news"
                      :options="paginated"
                      :filterable="false"
                      v-on:input="limiter"
                      @search="onSearch">
                      <li slot="list-footer" class="pagination prev-next">
                        <button class="btn btn-info" :disabled="!hasPrevPage" @click="prevPage">Prev</button>
                        <button class="btn btn-info" :disabled="!hasNextPage" @click="nextPage">Next</button>
                      </li>
                    </v-select>
                  </div>
                </div>
              </div>
<!--              <div class="col-md-6">-->
<!--                <fg-input type="radio"-->
<!--                          label="Template 1"-->
<!--                          name="choose_template"-->
<!--                          fou-->
<!--                          v-model="chooseTemplate[0]">-->
<!--                </fg-input>-->
<!--                <fg-input type="radio"-->
<!--                          label="Template 2"-->
<!--                          name="choose_template"-->
<!--                          fou-->
<!--                          v-model="chooseTemplate[1]">-->
<!--                </fg-input>-->
<!--              </div>-->
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/email-templates/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>
    </div>
    <div id="news" :key="selectTypeKey"  class="col-md-12">
<!--      <news-template  :key="selectTypeKey" v-if="formData.template_type === 'NEWS'" :news="formData.news" ></news-template>-->
      <news-template2  :key="selectTypeKey" v-if="formData.template_type === 'NEWS'" :news="formData.news" ></news-template2>
      <div :key="selectTypeKey" v-else-if="formData.template_type === 'ARTICLE'" v-html="formData.content"></div>
    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';
import vSelect from "vue-select";
import {Dialog} from 'element-ui'
import "vue-select/dist/vue-select.css";
import NewsTemplate from "../../../components/MailchimpTemplates/NewsTemplate";
import NewsTemplate2 from "../../../components/MailchimpTemplates/NewsTemplate2";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    NewsTemplate2,
    NewsTemplate,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor,
    vSelect
  },
  watch: {
    'formData.news': {
      handler: function(newValue) {
        this.selectTypeKey++
      },
      deep: true
    }
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        name: "",
        news: [],
        html: '',
        template_type: 'NEWS',
        content: '',
      },
      selectTypeKey: 0,
      newsList: [],
      newsItems:[],
      chooseTemplate: [
       true, false
      ],
      tempKey: 0,
      templateType: [
        {title: 'News', value: 'NEWS'},
        {title: 'New article', value: 'ARTICLE'},
      ],





      selectList: [],
      selectKey: 0,
      search: '',
      page: 1,
      limit: 10,
      selectLimiter: 12,
    };
  },
  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    }),
    paginated() {
      return this.selectList;
    },
    hasNextPage() {
      return this.selectList.length > this.limit - 1;
    },
    hasPrevPage() {
      return this.page !== 1;
    },
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.emailTemplateForm
    });

    let data = {
      item: 'كتائبيات',
      page: 1,
      page_size: 23,
      search: '',
    }
    this.axios.post("https://api.kataeb.org/front/news/search-news-with-category-url", data, {headers: {lang: 'ar'}}).then((response) => {
      this.newsList = response.data.news

      this.resetPaginator();
      this.formData.news = this.initData;
      this.dataPaginated();

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit template";
        this.getTemplate();
      } else {
        this.editMode = false;
        this.formTitle = "Add template";
        this.loader.hide();
      }
    }).catch((error) => {
      console.log(error)
      if (error.response.status === 400) {
        this.entityNotFoundError = true;
        this.$notify({
          message: "Api error",
          timeout: 2000,
          type: 'danger'
        });

      } else {
        console.error(error);
      }
    })


  },
  methods: {
    getTemplate() {
      let data = {
        'id' : this.id,
      };
      this.axios.post("email-templates/get",data).then((response) => {
        this.formData = response.data.results;
        this.formData.template_type = response.data.item.template_type;
        this.formData.news = response.data.item.news;
        this.formData.content = response.data.item.content;
        this.selectTypeKey++;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Template Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      this.formData.html = document.getElementById('news').innerHTML;
      if (this.editMode === true) {
        request = this.axios.put("email-templates/update/" + this.id, this.formData);
        successMessage = "Template Updated Successfully";
      } else {
        request = this.axios.post("email-templates/create", this.formData);
        successMessage = "Template Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/email-templates/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    selectChanged() {
      this.selectKey++;
      this.selectTypeKey++;
      this.$emit('select-changed', this.formData.news);
    },
    confirmModal() {
      this.$emit('confirm');
    },
    dataPaginated() {
      let data = {
        item: 'كتائبيات',
        page: this.page,
        page_size: this.limit,
        search: this.search,
      }
      this.axios.post("https://api.kataeb.org/front/news/search-news-with-category-url", data, {headers: {lang: 'ar'}}).then((response) => {
        this.selectList = response.data.news;
        // this.dataCount = response.data.dataCount;
      }).catch((error) => {
      })
    },
    resetPaginator() {
      this.page = 1;
    },
    nextPage() {
      this.page++;
      this.dataPaginated();
    },
    prevPage() {
      this.page--;
      this.dataPaginated();
    },
    onSearch(query) {
      this.search = query
      this.page = 1
      this.dataPaginated();
    },
    limiter(e) {
      if (this.selectLimiter) {
        if (e.length > this.selectLimiter) {
          e.pop()
        }
      }
    },
    changeSelect(){
      this.selectTypeKey++;
    }

  }
}
</script>
<style scoped>
.vs__dropdown-menu{
  display: block !important;
}
.el-dialog--center .el-dialog__footer {
  text-align: right !important;
}
.style-chooser .vs__selected {
  border-radius: 3px !important;
  border-color: #23CCEF !important;
  background-color: white !important;
  border-radius: 2px !important;
  color: #23CCEF !important;
}

.style-chooser .vs__deselect {
  background-color: transparent !important;
  color: #23CCEF !important;
  opacity: 1 !important;
  font-size: 12px !important;
}

.style-chooser .vs__dropdown-option--selected {
  color: #23CCEF !important;
}

.style-chooser .prev-next {
  margin-top: 15px !important;
}

.style-chooser .prev-next button {
  margin-right: 15px !important;
  margin-left: 10px !important;
}

</style>
